<template lang="pug">
form-input(
  :modelValue="modelValue"
  :label="label"
  :message="message"
  :message-type="messageType"
  :disabled="disabled"
  :placeholder="placeholder"
  :type="inputType"
  :icon="inputType === 'text' ? UiIconNames.Icon_EyeClose : UiIconNames.Icon_EyeOpen"
  :autocomplete="autocomplete"
  @click-button="changeInputType"
  @update:modelValue="$emit('update:modelValue', $event)"
)
</template>

<script lang="ts">
import type { PropType } from "vue";
import { defineComponent, ref } from "vue";

import FormInput from "@/components/ui/form/input/FormInput.vue";
import UiIconNames from "@/components/ui/icon/UiIconNames";

export default defineComponent({
  name: "PasswordInput",
  components: {
    FormInput,
  },
  props: {
    modelValue: {},
    disabled: Boolean,
    placeholder: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    message: {
      type: String,
      default: "",
    },
    messageType: {
      type: String as PropType<'error'|'info'>,
      default: 'info',
    },
    autocomplete: {
      type: String,
      default: 'off',
    }
  },
  setup() {
    const inputType = ref<'password'|'text'>("password");

    function changeInputType() {
      if (inputType.value === "text") {
        inputType.value = "password";
      } else {
        inputType.value = "text";
      }
    }

    return {
      inputType,
      changeInputType,
      UiIconNames,
    };
  },
});
</script>

<style scoped lang="scss">
::v-deep(.base-input) {
  padding: 8px 4px 8px 16px;
}
</style>
